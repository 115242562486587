import React from 'react'
import Computer from './Computer'
import Cellular from './Cellular'

function index() {
  return (
	<div>
		{window.innerWidth > 640 ?(
			<Computer/>
		):(
			<Cellular/>
		)}
	</div>
  )
}

export default index