import React from "react";
import { Footer } from "flowbite-react";
import Logo from "../../../assets/logo/webinal_right.png"

function Footers() {
	return (
		<div className="">
			<Footer container={true}>
				<div className="w-full">
					<div className="grid w-full mb-10 justify-between sm:flex sm:justify-between md:flex md:grid-cols-1">
						<div>
							<Footer.Brand
								href="/"
								src={Logo}
								alt="Webinal Logo"
								name=""
							/>
						</div>
						<div className="grid grid-cols-2 gap-8 sm:mt-4 sm:grid-cols-3 sm:gap-6">
							<div>
								<Footer.Title title="Contatti" />
								<Footer.LinkGroup col={true}>
									<Footer.Link href="mailto:info@webinal.it">E: info@webinal.it</Footer.Link>
									<Footer.Link href="tel:+393278547660">T: 327 8547660</Footer.Link>
								</Footer.LinkGroup>
							</div>
							<div>
								<Footer.Title title="Follow us" />
								<Footer.LinkGroup col={true}>
									<Footer.Link href="#">Instagram</Footer.Link>
									<Footer.Link href="#">Discord</Footer.Link>
								</Footer.LinkGroup>
							</div>
							<div>
								<Footer.Title title="Legal" />
								<Footer.LinkGroup col={true}>
									<Footer.Link href="#">Privacy Policy</Footer.Link>
									<Footer.Link href="#">Terms & Conditions</Footer.Link>
								</Footer.LinkGroup>
							</div>
						</div>
					</div>
					<Footer.Divider  />
					<div className="w-full sm:flex sm:items-center sm:justify-between">
						<Footer.Copyright href="#" by=" Webinal™" year={2023} />
						<div className="mt-4 flex space-x-6 sm:mt-0 sm:justify-center">
							
							
							
						</div>
					</div>
				</div>
			</Footer>
		</div>
	);
}

export default Footers;
