import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Cookies from "universal-cookie";
import NotificaAperta from "../../Components/Molecules/SchedaNotifica/NotificaAperta";

function Notifica() {
  const [notifica, setNotifica] = useState({});
  const [loaded, setLoaded] = useState(false);
  const slug = useLocation().pathname.substring(10);
  const cookie = new Cookies();

  var data;
  console.log("slug", slug);
  data = { token: cookie.get("token"), id: slug };

  const handleNotification = () => {
    const headers = { "Access-Control-Allow-Origin": "*" };
    const url = process.env.REACT_APP_API_URL + "/dash/notifica";

    axios.post(url, data, { headers }).then((response) => {
      console.log(response);
      setNotifica(response.data.notifica);
      setLoaded(true);
    });
  };

  useEffect(() => {
    handleNotification();
  }, []);

  return (
    <div>
      {loaded ? (
        <div  className='mt-24 mx-12'>
          <NotificaAperta
         
          props={notifica[0]} />
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
}

export default Notifica;
