import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import Cookies from "universal-cookie";
import IsLogged from "../../Components/Auth/IsLogged/IsLogged";
import lock from "../../assets/icons/lock-solid.svg";
import Loading from "../../Components/Molecules/Loading/Loading";
import Title from "../../Components/Atoms/Texts/Title";
import NEWARTbtn from "../../Components/Atoms/Buttons/NEWARTbtn/NEWARTbtn";
import SchedaContenuto from "../../Components/Molecules/SchedaContenuto/SchedaContenuto";
import AccessoEsclusivo from "../../Components/Atoms/AccessoEsclusivo/AccessoEsclusivo";

function Contenuti() {
	IsLogged();
	const cookie = new Cookies();
	var data;
	data = { token: cookie.get("token") };

	const [loaded, setLoaded] = useState(false);
	const [contenuti, setContenuti] = useState([]);

	const handleGet = async () => {
		const headers = {
			"Access-Control-Allow-Origin": "*",
		};

		const url = process.env.REACT_APP_API_URL + "/dash/getcontenuti";
		axios
			.post(url, data, { headers })
			.then((res) => {
				setContenuti(res.data.contenuti.reverse());
			})
			.catch((err) => {
				console.log("err", err);
			});
	};

	useEffect(() => {
		handleGet();
		setTimeout(() => setLoaded(true), 1000);
	}, []);

	return (
		<div className="bg-background w-full h-[100vh]">
			{!loaded ? (
				<div></div>
			) : (
				<>
					<div className="mt-36 px-44 relative">
						<div className="flex justify-between">
							<Title text={"Blog"} />
							<NEWARTbtn
								title={"Nuovo articolo"}
								svg={
									<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
										<path d="M0 64C0 28.7 28.7 0 64 0H224V128c0 17.7 14.3 32 32 32H384v38.6C310.1 219.5 256 287.4 256 368c0 59.1 29.1 111.3 73.7 143.3c-3.2 .5-6.4 .7-9.7 .7H64c-35.3 0-64-28.7-64-64V64zm384 64H256V0L384 128zm48 384c-79.5 0-144-64.5-144-144s64.5-144 144-144s144 64.5 144 144s-64.5 144-144 144zm16-208c0-8.8-7.2-16-16-16s-16 7.2-16 16v48H368c-8.8 0-16 7.2-16 16s7.2 16 16 16h48v48c0 8.8 7.2 16 16 16s16-7.2 16-16V384h48c8.8 0 16-7.2 16-16s-7.2-16-16-16H448V304z" />
									</svg>
								}
								link="/contenuto"
							/>
						</div>
						<div className="grid grid-cols-1 gap-3 mt-10">
							{contenuti.map((contenuto, index) => {
								if (!contenuto.eliminato) {
									return <SchedaContenuto key={index} data={contenuto} />;
								}
							})}
						</div>
					</div>
				</>
			)}
		</div>
	);
}

export default Contenuti;
