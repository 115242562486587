import React, { Component } from "react";
import ReactQuill, { Quill } from "react-quill";
// #1 import quill-image-uploader
import ImageUploader from "quill-image-uploader";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import storage from "../../../firebase";
// #2 register module
Quill.register("modules/imageUploader", ImageUploader);

class Editor extends Component {
	constructor(props) {
		super(props);
		this.state = { editorHtml: this.props.testo };
		this.handleChange = this.handleChange.bind(this);
		this.textInput = React.createRef();
	}

	handleChange(html) {
		this.setState({ editorHtml: html });
        this.props.setTesto(html);
	}

	handleSubmit() {
		const editor = this.reactQuillRef.getEditor();
		this.setState({
			editorHtml: editor,
		});
	}
	modules = {
		// #3 Add "image" to the toolbar
		toolbar: [
			[{ header: [1, 2, false] }],
			["bold", "italic", "underline", "strike", "blockquote"],
			[
				{ list: "ordered" },
				{ list: "bullet" },
				{ indent: "-1" },
				{ indent: "+1" },
			],
			[{ 'align': [] }],
			["link", "image"],
			["clean"],
		],
		// # 4 Add module and upload function
		imageUploader: {
			upload: async (file) => {
				return new Promise(async (resolve, reject) => {
					const fileName = new Date().getTime() + file.name;
					const storageRef = ref(storage, `/images/${fileName}`);
					const uploadTask = uploadBytesResumable(storageRef, file);
					uploadTask.on(
						"state_changed",
						(snapshot) => {
							const uploaded = Math.floor(
								(snapshot.bytesTransferred / snapshot.totalBytes) * 100
							);
							console.log("uploaded", uploaded);
						},
						(error) => {
							console.log(error);
						},
						() => {
							getDownloadURL(uploadTask.snapshot.ref).then((url) => {
								resolve(url);
							});
						}
					);
				});
			},
		},
	};

	formats = [
		"header",
		"bold",
		"italic",
		"underline",
		"strike",
		"blockquote",
		"list",
		"bullet",
		"indent",
		"align",
		"link",
		"image",
		"imageBlot", // #5 Optinal if using custom formats
	];

	render() {
		return (
			<>
				{false && <div dangerouslySetInnerHTML={{ __html: this.state.editorHtml }} />}

				<ReactQuill
					onChange={this.handleChange}
					theme="snow"
					style={{
						minHeight: "25vh",
					}}
					modules={this.modules}
					formats={this.formats}
					value={this.state.editorHtml}
				/>
			</>
		);
	}
}

export default Editor;
