import React, { useState } from "react";
import { useRef } from "react";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import storage from "../../../../firebase";
import Loader from "../../Loader/Loader";

function FileUploadbtn({
	name,
	label,
	value,
	type,
	handleInputState,
	...rest
}) {
	const inputRef = useRef();
	const [progress, setProgress] = useState(0);
	const [progressShow, setProgressShow] = useState(false);

	const handleUpload = () => {
		setProgressShow(true);
		const fileName = new Date().getTime() + value.name;
		const storageRef = ref(
			storage,
			type === "audio" ? `/audio/${fileName}` : `/images/${fileName}`
		);
		const uploadTask = uploadBytesResumable(storageRef, value);
		uploadTask.on(
			"state_changed",
			(snapshot) => {
				const uploaded = Math.floor(
					(snapshot.bytesTransferred / snapshot.totalBytes) * 100
				);
				setProgress(uploaded);
			},
			(error) => {
				console.log(error);
			},
			() => {
				getDownloadURL(uploadTask.snapshot.ref).then((url) => {
					handleInputState(name, url);
				});
			}
		);
	};

	return (
		<div className="flex justify-center items-center space-x-4  cursor-pointer">
			<input
				type="file"
				ref={inputRef}
				onChange={(e) => {
					setProgress(0);
					setProgressShow(false);
					handleInputState(name, e.currentTarget.files[0]);
				}}
				vlaue={value}
				{...rest}
				style={{ display: "none" }}
			/>
			<button type="button" onClick={() => inputRef.current.click()}>
				<i className="fa-solid fa-upload text-3xl text-orange"></i>
				<p className="text-orange font-semibold ">Carica immagine</p>
			</button>
			{type === "image" && value && (
				<img
					src={typeof value === "string" ? value : URL.createObjectURL(value)}
					alt="file"
				/>
			)}
			{value !== null && !progressShow && typeof value !== "string" && (
				<button className="" onClick={() => handleUpload()}>
					Conferma
				</button>
			)}
			<div className="relative ml-5">
				<div className="absolute left-0">
					{progressShow && progress < 100 && <Loader />}
				</div>
				<div className="absolute left-0">
					{progress === 100 && (
						<i className="fa-solid fa-check text-4xl text-orange"></i>
					)}
				</div>
			</div>
		</div>
	);
}

export default FileUploadbtn;
