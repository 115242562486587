import React, { useEffect } from "react";
import "./Home.css";
import { gsap, ScrollTrigger, Draggable, MotionPathPlugin } from "gsap/all";
import SplitType from "split-type";
import Footers from "../../Components/Molecules/Footers/Footers";

function Computer() {
	gsap.registerPlugin(ScrollTrigger, Draggable, MotionPathPlugin);

	useEffect(() => {
		gsap.set("#testo1", { x: 2000 });
		gsap.set("#testo2", { x: -14000 });

		gsap
			.timeline()
			.fromTo(
				"#savo1",
				{ x: 1000, y: -2500 },
				{ x: 0, y: 0, delay: 0.5, duration: 1 }
			)
			.fromTo("#wht1", { x: 0 }, { x: 50, duration: 0.2 })
			.fromTo("#testo1", { opacity: 0 }, { x: 2000, duration: 0.1 })
			.to("#testo1", { opacity: 1 })
			.fromTo(
				"#testo1",
				{ x: 2000 },
				{ x: -2000, ease: "easeInOut", duration: 16 }
			)
			.fromTo("#testo1", { x: -2000 }, { x: 2000, duration: 10 });
		gsap
			.timeline()
			.fromTo(
				"#savo2",
				{ x: -1000, y: 2500 },
				{ x: 0, y: 0, delay: 0.5, duration: 1 }
			)
			.fromTo("#wht2", { x: 0 }, { x: 50, duration: 0.2 })
			.fromTo("#testo2", { opacity: 0 }, { x: -14000, duration: 0.1 })
			.to("#testo2", { opacity: 1 })
			.fromTo(
				"#testo2",
				{ x: -14000 },
				{ x: -10000, ease: "easeInOut", duration: 16 }
			)
			.fromTo("#testo2", { x: -10000 }, { x: -14000, duration: 10 });

		const ChiSiamo = SplitType.create("#chi_siamo", { types: "words" });
		const ChiSiamoChars = ChiSiamo.words;
		gsap.fromTo(
			[ChiSiamoChars, "#chi_siamo_text"],
			{
				y: 100,
				opacity: 0,
			},
			{
				y: 0,
				opacity: 1,
				stagger: 0.05,
				duration: 1,
				ease: "power4.out",
				scrollTrigger: {
					trigger: "#chi_siamo",
					start: "top 60%",
					end: "bottom 60%",
				},
			}
		);
		gsap.fromTo(
			["#chi_siamo_riga"],
			{
				x: 1000,
				opacity: 0,
			},
			{
				x: 0,
				opacity: 1,
				stagger: 0.05,
				duration: 0.5,
				ease: "power4.out",
				scrollTrigger: {
					trigger: "#chi_siamo",
					start: "top 60%",
					end: "bottom 60%",
				},
			}
		);

		// progetti
		gsap
			.timeline()
			.set(".ring", { rotationY: 180, cursor: "grab" }) //set initial rotationY so the parallax jump happens off screen
			.set(".img", {
				// apply transform rotations to each image
				rotateY: (i) => i * -36,
				transformOrigin: "50% 50% 1500px",
				z: -1500,
				/*backgroundImage: (i) =>
					"url(https://picsum.photos/id/" + (i + 32) + "/600/400/)",*/
				backgroundPosition: (i) => getBgPos(i),
				backfaceVisibility: "hidden",
			});

		window.addEventListener("mousedown", dragStart);
		window.addEventListener("mouseup", dragEnd);
	});
	let xPos = 0;
	function dragStart(e) {
		if (e.touches) e.clientX = e.touches[0].clientX;
		xPos = Math.round(e.clientX);
		gsap.set(".ring", { cursor: "grabbing" });
		window.addEventListener("mousemove", drag);
	}

	function drag(e) {
		if (e.touches) e.clientX = e.touches[0].clientX;

		gsap.to(".ring", {
			rotationY: "-=" + ((Math.round(e.clientX) - xPos) % 80),
			onUpdate: () => {
				gsap.set(".img", { backgroundPosition: (i) => getBgPos(i) });
			},
		});

		xPos = Math.round(e.clientX);
	}

	function dragEnd(e) {
		window.removeEventListener("mousemove", drag);
		gsap.set(".ring", { cursor: "grab" });
	}

	function getBgPos(i) {
		//returns the background-position string to create parallax movement in each image
		return (
			100 -
			(gsap.utils.wrap(
				0,
				360,
				gsap.getProperty(".ring", "rotationY") - 180 - i * 38
			) /
				360) *
				500 +
			"px 0px"
		);
	}

	return (
		<>
			<div className="w-full hidden mt-20 md:px-64 md:py-24 bg-background overflow-x-hidden ">
				{window.innerWidth > 640 ? (
					<div className="w-full h-[80vh] flex justify-center">
						<div className="h-[80vh] w-full relative">
							<img
								className="absolute w-[14rem] left-0"
								src={require("../../assets/parti/pallino.png")}
								alt=""
							/>
							<div id="savo1" className="absolute w-[28rem] left-[8rem]">
								<img
									id="org1"
									className="absolute z-20"
									src={require("../../assets/parti/lunga2.png")}
									alt=""
								/>
								<img
									id="wht1"
									className="absolute z-10"
									src={require("../../assets/parti/white2.png")}
									alt=""
								/>
							</div>
							<div id="savo2" className="z-30 absolute w-[28rem] left-[32rem]">
								<img
									id="org2"
									className="absolute z-30"
									src={require("../../assets/parti/lunga2.png")}
									alt=""
								/>
								<img
									id="wht2"
									className="absolute z-10"
									src={require("../../assets/parti/white2.png")}
									alt=""
								/>
							</div>
							<div className="absolute z-20 w-[110vw] left-[-30%] rotate-12 top-[40%] overflow-hidden object-cover">
								<p
									id="testo1"
									className="m-2 p-2  z-20 text-9xl relative w-[20000px] text-white overflow-x-hidden"
								>
									Webinal HTML Javascript CSS React Nodejs Expressjs CEO website
									landing Webinal
								</p>
								<p
									id="testo2"
									className="m-2 p-2 z-20 text-9xl relative w-[20000px] text-white overflow-x-hidden"
								>
									Webinal HTML Javascript CSS React Nodejs Expressjs CEO Webinal
									Webinal HTML Javascript CSS React Nodejs Expressjs CEO Webinal
									Webinal HTML Javascript CSS React Nodejs Expressjs CEO Webinal
								</p>
							</div>
						</div>
					</div>
				) : (
					<p>CELLULARE</p>
				)}
			</div>

			<div className="grid grid-cols-2 gap-24 w-full mt-64  text-white ">
				<div className="relative pl-40 ">
					<h3 className="text-[9rem] font-semibold">Webinal</h3>
					<p className="text-[3rem]">
						Il tuo partner per la <br/> creazione di siti web
					</p>
					<button
						className=" px-24 border-2 mt-5 border-white rounded-full py-8 hover:scale-110 transition-all duration-300 bg-white text-black font-semibold text-2xl z-10  "
						onClick={() => window.location.href= '/contatti'}
					>
						Contattaci
					</button>
				</div>
				<div className="flex justify-center items-center">
					<img width={'70%'} src={require('../../assets/logo/webinal.png')}  alt="" />
				</div>
			</div>

			<div className="md:mt-44 md:px-64 text-white">
				<div className="block">
					<p
						id="chi_siamo"
						className="text-6xl w-auto uppercase p-2 font-semibold "
					>
						Chi siamo
					</p>
				</div>
				<div id="chi_siamo_riga" className="w-full h-1 bg-purple"></div>
				<div className="w-[36rem] text-2xl text-justify mt-10">
					<p id="chi_siamo_text">
						Il nostro team è composto da professionisti che si dedicano con
						passione alla creazione di soluzioni web personalizzate per i nostri
						clienti. Offriamo competenze diverse, ma siamo tutti legati dalla
						passione per il nostro lavoro e dalla volontà di offrire il meglio
						ai nostri clienti.
					</p>
				</div>
			</div>
			<div className="mt-96 text-white">
				<div className="block px-64">
					<p
						id="chi_siamo"
						className="text-6xl w-auto text-right uppercase p-2 font-semibold "
					>
						I nostri progetti
					</p>
					<div id="chi_siamo_riga" className="w-full h-1 bg-purple"></div>
				</div>
				<div className="w-[38rem] h-[100vh] text-2xl text-justify mt-10">
					<div className="stage">
						<div className="progetti">
							<div className="ring shadow-none">
								<div className="img w-48 h-32 bg-chimera relative ">
									{" "}
									<button
										className=" px-12 border-2 border-white rounded-full py-8 bg-purple text-white z-10 absolute top-[80%] left-[50%] transform -translate-x-1/2"
										onClick={() =>
											window.open("https://chimerastudioss.com", "_blank")
										}
									>
										Scopri di più
									</button>
								</div>
								<div className="img w-48 h-32 bg-sardi ">
									<button
										className=" px-12 border-2 border-white rounded-full py-8 bg-purple text-white z-10 absolute top-[80%] left-[50%] transform -translate-x-1/2"
										onClick={() =>
											window.open("https://sarditable.it", "_blank")
										}
									>
										Scopri di più
									</button>
								</div>
								<div className="img w-48 h-32 bg-apeiron ">
									<button
										className=" px-12 border-2 border-white rounded-full py-8 bg-purple text-white z-10 absolute top-[80%] left-[50%] transform -translate-x-1/2"
										onClick={() =>
											window.open("https://apeironstudios.net", "_blank")
										}
									>
										Scopri di più
									</button>
								</div>
								<div className="img w-48 h-32 bg-togiftit ">
									<button
										className=" px-12 border-2 border-white rounded-full py-8 bg-purple text-white z-10 absolute top-[80%] left-[50%] transform -translate-x-1/2"
										onClick={() =>
											window.open("https://togiftit.com", "_blank")
										}
									>
										Scopri di più
									</button>
								</div>
								<div className="img w-48 h-32 bg-moiramichelini">
									<button
										className=" px-12 border-2 border-white rounded-full py-8 bg-purple text-white z-10 absolute top-[80%] left-[50%] transform -translate-x-1/2"
										onClick={() =>
											window.open("https://moiramichelini.it", "_blank")
										}
									>
										Scopri di più
									</button>
								</div>
								<div className="img w-48 h-32 bg-mondrone">
									<button
										className=" px-12 border-2 border-white rounded-full py-8 bg-purple text-white z-10 absolute top-[80%] left-[50%] transform -translate-x-1/2"
										onClick={() =>
											window.open("https://mondrone.it/nuovo", "_blank")
										}
									>
										Scopri di più
									</button>
								</div>
								<div className="img w-48 h-32 bg-chimera relative ">
									{" "}
									<button
										className=" px-12 border-2 border-white rounded-full py-8 bg-purple text-white z-10 absolute top-[80%] left-[50%] transform -translate-x-1/2"
										onClick={() =>
											window.open("https://chimerastudioss.com", "_blank")
										}
									>
										Scopri di più
									</button>
								</div>
								<div className="img w-48 h-32 bg-sardi ">
									<button
										className=" px-12 border-2 border-white rounded-full py-8 bg-purple text-white z-10 absolute top-[80%] left-[50%] transform -translate-x-1/2"
										onClick={() =>
											window.open("https://sarditable.it", "_blank")
										}
									>
										Scopri di più
									</button>
								</div>
								<div className="img w-48 h-32 bg-apeiron ">
									<button
										className=" px-12 border-2 border-white rounded-full py-8 bg-purple text-white z-10 absolute top-[80%] left-[50%] transform -translate-x-1/2"
										onClick={() =>
											window.open("https://apeironstudios.net", "_blank")
										}
									>
										Scopri di più
									</button>
								</div>
								<div className="img w-48 h-32 bg-togiftit ">
									<button
										className=" px-12 border-2 border-white rounded-full py-8 bg-purple text-white z-10 absolute top-[80%] left-[50%] transform -translate-x-1/2"
										onClick={() =>
											window.open("https://togiftit.com", "_blank")
										}
									>
										Scopri di più
									</button>
								</div>
								<div className="img w-48 h-32 bg-moiramichelini">
									<button
										className=" px-12 border-2 border-white rounded-full py-8 bg-purple text-white z-10 absolute top-[80%] left-[50%] transform -translate-x-1/2"
										onClick={() =>
											window.open("https://moiramichelini.it", "_blank")
										}
									>
										Scopri di più
									</button>
								</div>
								<div className="img w-48 h-32 bg-mondrone">
									<button
										className=" px-12 border-2 border-white rounded-full py-8 bg-purple text-white z-10 absolute top-[80%] left-[50%] transform -translate-x-1/2"
										onClick={() =>
											window.open("https://mondrone.it/nuovo", "_blank")
										}
									>
										Scopri di più
									</button>
								</div>
								<div className="img w-48 h-32 bg-chimera relative ">
									{" "}
									<button
										className=" px-12 border-2 border-white rounded-full py-8 bg-purple text-white z-10 absolute top-[80%] left-[50%] transform -translate-x-1/2"
										onClick={() =>
											window.open("https://chimerastudioss.com", "_blank")
										}
									>
										Scopri di più
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
				<Footers />
			</div>
		</>
	);
}

export default Computer;