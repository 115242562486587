import React from 'react'

function NotificaAperta({props}) {
  return (
    <div className='bg-cultured rounded-xl p-7 grid grid-cols-1 gap-3'>
        <h3 className='text-orange text-lg font-bold'>
            Nuovo annuncio da: {props.name} {props.lastname}
        </h3>
        <p className='text-orange font-semibold'>
            Richiesta da: {props.page}
        </p>
        <p className='text-orange font-semibold'>
            Telefono: {props.tel}
        </p>
        <p className='text-orange font-semibold'>
            Email: {props.mail}
        </p>
        <p className='text-orange font-semibold'>
            {props.paese}/{props.provincia}/{props.cap}
        </p>
        <p className='text-orange font-semibold'>
            Messaggio:
        </p>
        <p className='text-orange'>
            {props.msg}
        </p>
        
    </div>
  )
}

export default NotificaAperta