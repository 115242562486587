import React from "react";
import Footers from "../../Components/Molecules/Footers/Footers";

function Cellular() {
	return (
		<div className="flex flex-col space-y-24">
			<div className="text-white mt-36 grid place-content-center gap-8 text-center uppercase text-6xl">
				<h1>webinal</h1>
				<img
					src={require("../../assets/logo/webinal.png")}
					className="max-w-[90vw]"
					alt=""
				/>
			</div>
			<div className=" text-white grid place-content-center">
				<div className="block">
					<p
						id="chi_siamo"
						className="text-6xl w-auto uppercase p-2 font-semibold "
					>
						Chi siamo
					</p>
				</div>
				<div id="chi_siamo_riga" className="w-[95vw] h-1 bg-purple"></div>
				<div className="w-[95vw] text-md text-justify mt-10">
					<p id="chi_siamo_text">
						Il nostro team è composto da professionisti che si dedicano con
						passione alla creazione di soluzioni web personalizzate per i nostri
						clienti. Offriamo competenze diverse, ma siamo tutti legati dalla
						passione per il nostro lavoro e dalla volontà di offrire il meglio
						ai nostri clienti.
					</p>
				</div>
			</div>
			<div className=" text-white grid place-content-center">
				<div className="block">
					<p className="text-6xl w-auto uppercase p-2 font-semibold ">
						I nostri progetti
					</p>
				</div>
				<div className="w-[95vw] my-5 h-1 bg-purple"></div>
				<div className="text-xs">
					<div className="relative w-[100vw] h-[70vw] bg-sardi bg-contain bg-no-repeat ">
						<button
							className=" px-3 border-2 border-white rounded-full py-3 bg-purple text-white z-10 absolute top-[75%] -translate-y-1/2 left-[50%] transform -translate-x-1/2"
							onClick={() => window.open("https://sarditable.it", "_blank")}
						>
							Scopri di più
						</button>
					</div>
					<div className="relative w-[100vw] h-[70vw] bg-chimera bg-contain bg-no-repeat ">
						<button
							className=" px-3 border-2 border-white rounded-full py-3 bg-purple text-white z-10 absolute top-[75%] -translate-y-1/2 left-[50%] transform -translate-x-1/2"
							onClick={() =>
								window.open("https://chimerastudioss.com", "_blank")
							}
						>
							Scopri di più
						</button>
					</div>
					<div className="relative w-[100vw] h-[70vw] bg-apeiron bg-contain bg-no-repeat ">
						<button
							className=" px-3 border-2 border-white rounded-full py-3 bg-purple text-white z-10 absolute top-[75%] -translate-y-1/2 left-[50%] transform -translate-x-1/2"
							onClick={() =>
								window.open("https://apeironstudios.net", "_blank")
							}
						>
							Scopri di più
						</button>
					</div>
					<div className="relative w-[100vw] h-[70vw] bg-moiramichelini bg-contain bg-no-repeat ">
						<button
							className=" px-3 border-2 border-white rounded-full py-3 bg-purple text-white z-10 absolute top-[75%] -translate-y-1/2 left-[50%] transform -translate-x-1/2"
							onClick={() => window.open("https://moiramichelini.it", "_blank")}
						>
							Scopri di più
						</button>
					</div>
					
					<div className="relative w-[100vw] h-[70vw] bg-mondrone bg-contain bg-no-repeat ">
						<button
							className=" px-3 border-2 border-white rounded-full py-3 bg-purple text-white z-10 absolute top-[75%] -translate-y-1/2 left-[50%] transform -translate-x-1/2"
							onClick={() =>
								window.open("https://mondrone.it/nuovo", "_blank")
							}
						>
							Scopri di più
						</button>
					</div>
					<div className="img w-48 h-32 bg-togiftit">
									<button
										className=" px-12 border-2 border-white rounded-full py-8 bg-purple text-white z-10 absolute top-[80%] left-[50%] transform -translate-x-1/2"
										onClick={() =>
											window.open("https://togitit.com", "_blank")
										}
									>
										Scopri di più
									</button>
								</div>
					
				</div>
			</div>
			<Footers />
		</div>
	);
}

export default Cellular;
