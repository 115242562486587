import React, { useState } from "react";
import LOCKbtn from "../Atoms/Buttons/LOCKbtn";
import { scrollTo } from "../../utils/scrollTo";
import { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import Loading from "./Loading/Loading";

function DinamIcIsland({ load }) {
	const slug = useLocation().pathname;
	const [session, setSession] = useState(false);
	const [loaded, setLoaded] = useState(true);
	const [loadcheck, setLoadcheck] = useState(false);

	const [menu, setMenu] = useState([
		{ title: "Contatti", id: "contatti" },
	]);
	var open;

	

	useEffect(() => {
		setSession(window.sessionStorage.getItem("logged"));
		if (window.innerWidth > 640) {
			if (session) {
				setMenu([
					{ title: "Admin", url: "admin" },
					{ title: "Notifiche", url: "/notifiche" },
					{ title: "Contenuti", url: "/contenuti" },
				]);
			} else {
				setMenu([
					{ title: "Su di Noi", id: "sudinoi" },
					{ title: "Lavori", id: "lavori" },
					{ title: "Contatti", id: "contatti", url: "contatti" },
				]);
			}
		} else {
			setMenu([]);
		}


		window.addEventListener("scroll", () => {
			const topscroll = window.pageYOffset;
			if (topscroll >= 100 || slug === "/login") {
				open = false;
			} else if (true) {
				open = true;
			}
			resize();
		});

		document.getElementById("island").addEventListener("mouseover", () => {
			
			if (slug !== "/login") {
				if (loaded) {
					open = true;
					resize();
				}
			}

			resize();
		});
		document.getElementById("island").addEventListener("mouseout", () => {
			if (window.pageYOffset >= 100 || slug === "/login") {
				open = false;
			}
			resize();
		});

		if (!loadcheck) {
			for (let i = 0; i < load.length; i++) {
				if (load[i] === slug) {
					open = false;
					resize();
					setLoaded(false);
					console.log("trovato slug");
					loading()
				}
			}
			setLoadcheck(true);
		}

	}, [slug, session, loaded]);

	const resize = () => {
		const dnm = document.getElementById("island");
		const menu = document.getElementById("menu");
		const lock = document.getElementById("lock");
		const wi = window.innerWidth;
		if (open) {
			if (wi < 1050) {
				if (wi < 768) {
					if (wi < 640) {
						dnm.style.width = "375px";
					} else {
						dnm.style.width = "630px";
					}
				} else {
					dnm.style.width = "750px";
				}
			} else {
				dnm.style.width = "1050px";
			}
			menu.style.opacity = "100%";
			//lock.classList.add('animate-spin');
		} else {
			if (wi < 640) {
				dnm.style.width = "300px";
			} else {
				dnm.style.width = "500px";
			}
			menu.style.display = "none";
			menu.style.opacity = "0%";
			menu.style.display = "grid";
			//lock.classList.remove('animate-spin');
		}
	};

	const loading = () => {
		const dnm = document.getElementById("island");
		const dnm_bg = document.getElementById("dnm_bg");
		const menu = document.getElementById("menu");
		menu.style.display = "none";
		dnm.classList.replace("duration-200", "duration-500");
		dnm_bg.style.display = "block";
		open = false;
		resize();
		dnm.style.top = "50%";
		dnm.classList.remove("bg-white");
		dnm.classList.add("box", "bg-background");
		setTimeout(() => {
			menu.style.display = 'grid';
			open = true;
			setLoaded(true);
			dnm.style.top = "0%";
			dnm.classList.add("bg-white");
			dnm.classList.remove("box", "bg-background");
			dnm_bg.style.display = "none";
			resize();
			setTimeout(() => {
				setLoaded(true);
				dnm.classList.replace("duration-500", "duration-200");
				resize();
			}, 300);
			resize();
		}, 2000);
	};

	return (
		<div>
			<div
				id="island"
				className=" transition-all isl flex duration-200 top-0 align-middle z-50 fixed justify-between  h-16 w-[95vw] md:w-[750px] lg:w-[1050px] bg-white rounded-full p-1  mt-7 ml-[50%] translate-x-[-50%] shadow-lg"
			>
				<div className="flex align-middle w-full z-50  justify-between bg-white rounded-full ">
					{loaded && (
						<div className="flex isl items-center">
							<img
								className="row-start-1 isl cursor-pointer w-32 ml-3"
								src={require("../../assets/logo/webinal_right.png")}
								alt=""
								onClick={() => (window.location.href = "/")}
							/>
						</div>
					)}
					{!loaded && (
						<div className="flex w-full justify-center items-center">
							<Loading />
						</div>
					)}
					<div className={`${loaded ? 'flex' : 'hidden'}`}>
						<div
							id="menu"
							className="isl transition-all duration-300 grid grid-cols-3 gap-6 justify-center align-middle text-center col-start-3"
						>
							{menu.map((item, i) => {
								return (
									<p
										key={i}
										className="flex isl justify-center items-center cursor-pointer font-semibold text-black"
										onClick={() => {
											item.url
												? (window.location.href = item.url)
												: scrollTo({ id: item.id, duration: 1450 });
										}}
									>
										{item.title}
									</p>
								);
							})}
						</div>
						<div
							id="lock"
							className="flex transition-all duration-200 items-center px-7"
						>
							<LOCKbtn url={session < 1} />
						</div>
					</div>
				</div>
			</div>
			<div
				id="dnm_bg"
				className="hidden z-40 transition-all duration-200 fixed top-0 left-0 w-[100vw] h-[100vh] bg-background"
			></div>
		</div>
	);
}

export default DinamIcIsland;
