import React from "react";
import ContenutoNuovo from "../../Components/Molecules/SchedaContenuto/ContenutoNuovo";
import Title from "../../Components/Atoms/Texts/Title";
import NEWARTbtn from "../../Components/Atoms/Buttons/NEWARTbtn/NEWARTbtn";
import IsLogged from "../../Components/Auth/IsLogged/IsLogged";

function Contenuto() {
  IsLogged();
  return (
    
    <div className="mt-36 px-44 ">
      <Title text={"Nuovo Articolo"} />
      <ContenutoNuovo nuovo={true}/>
      
    </div>
  );
}

export default Contenuto;
