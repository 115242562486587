import React from "react";

function Loading() {
	return (
		<div className="">
			<h2 className="text-orange text-3xl font-bold textshadow">Loading</h2>
		</div>
	);
}

export default Loading;
