import axios from "axios";
import React, { useState } from "react";

function Contatti() {
    const [data, setData] = useState({
		name: "",
		lastname: "",
		tel: "",
		mail: "",
		paese: "",
		provincia: "",
		citta: "",
		cap: "",
		msg: "",
	});
    const [send, setSend] = useState(false);

	const handleSubmit = async (e) => {
        setSend(true);
		e.preventDefault();
		const headers = {
			"Access-Control-Allow-Origin": "*",
		};
		const url = "https://webinal.web.app/api/mail/webinal";

		await axios.post(url, data, { headers }).then((res) => {
			console.log(res);
			if (!res.error) {
				window.alert("Messaggio inviato con successo");
        setSend(false);
			} else {
				window.alert("Errore nell'invio del messaggio");
			}
		});
	};

	return (
		<div className=" grid lg:grid-cols-2 mx-auto mt-64">
			<div className="lg:flex hidden aspect-square items-start justify-center">
				<img
					src={require("../../assets/logo/webinal.png")}
					
					alt=""
                    className="object-contain w-[70%]"
				/>
			</div>
			<div className=" flex flex-col items-center">
				<h1 className="text-8xl text-white font-bold mb-8">Contattaci</h1>
				<form onSubmit={handleSubmit} className="w-full px-44">
					<div className="mb-4">
						<label htmlFor="nome" className="block text-gray-300">
							Nome
						</label>
						<input
							type="text"
							id="nome"
							className="bg-gray-800 text-gray-100 focus:outline-none rounded-xl p-3 w-full"
							value={data.name}
							onChange={(e) => setData({...data, name:e.target.value})}
							required
							placeholder="Nome"
						/>
					</div>
					<div className="mb-4">
						<label htmlFor="email" className="block text-gray-300">
							Email
						</label>
						<input
							type="email"
							id="email"
							className="bg-gray-800 text-gray-100 focus:outline-none rounded-xl p-3 w-full"
							value={data.mail}
                            onChange={(e) => setData({...data, mail:e.target.value})}
							required
							placeholder="Email"
						/>
					</div>
					<div className="mb-4">
						<label htmlFor="messaggio" className="block text-gray-300">
							Messaggio
						</label>
						<textarea
							id="messaggio"
							rows="4"
							className="bg-gray-800 text-gray-100 focus:outline-none rounded-xl p-3 w-full"
							value={data.msg}
                            onChange={(e) => setData({...data, msg:e.target.value})}
							required
							placeholder="Scrivi qui..."
						/>
					</div>
					<button
						type="submit"
						className="bg-purple transition-all duration-300 text-white text-xl rounded-full py-4 px-20 font-semibold hover:scale-110 hover:bg-orange-600"
					>
						Invia
					</button>
				</form>
			</div>
		</div>
	);
}

export default Contatti;
