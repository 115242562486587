import React from "react";
import './Triangle.css';

function PopUp({hover, logged}) {
	return (
		<div className={`${hover ? 'flex': 'hidden'} absolute text-center translate-y-[60%] items-center flex-col`}>
			<div className=" triangle border-orange"></div>
			<div className="rounded-3xl bg-white w-40 text-orange font-semibold p-2 border-2 border-solid border-orange">
				<p>{logged ? 'Esci': 'Area Clienti'}</p>
			</div>
		</div>
	);
}

export default PopUp;
