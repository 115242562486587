import React, { useState } from "react";
import { useEffect } from "react";
import PopUp from "../PopUp/PopUp";
import Cookies from "universal-cookie";

function LOCKbtn({ url }) {
	const cookie = new Cookies();
	const [loaded, setLoaded] = useState(false);
	const [session, setSession] = useState(0);
	const [hover, setHover] = useState(false);

	useEffect(() => {
		setSession(window.sessionStorage.getItem("logged"));
		setTimeout(() => {
			setSession(window.sessionStorage.getItem("logged"));
			setLoaded(true);
		}, 100);
	}, [url]);

	const login = () => {
		window.location.href = "/login";
	};

	const logout = () => {
		window.sessionStorage.removeItem("logged");
		cookie.remove("token");
		window.location.href = "/";
	};

	return (
		<>
			{!loaded ? (
				<div></div>
			) : (
				<div
					onClick={() => {
						url ? login() : logout();
					}}
					onMouseEnter={() => setHover(true)}
					onMouseLeave={() => setHover(false)}
					className="flex justify-center"
				>
					{!session ? (
						<i
							className={`text-purple cursor-pointer fa-solid fa-lock text-xl`}
						></i>
					) : (
						<i
							className={`text-purple cursor-pointer fa-solid fa-lock-open text-xl`}
						></i>
					)}
					<PopUp hover={hover} logged={session > 0} />
				</div>
			)}
		</>
	);
}

export default LOCKbtn;
