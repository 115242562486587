import React, { useEffect, useState } from "react";
import ContenutoNuovo from "../../Components/Molecules/SchedaContenuto/ContenutoNuovo";
import Title from "../../Components/Atoms/Texts/Title";
import NEWARTbtn from "../../Components/Atoms/Buttons/NEWARTbtn/NEWARTbtn";
import Cookies from "universal-cookie";
import axios from "axios";
import { useLocation, useParams } from "react-router-dom";
import Loading from "../../Components/Molecules/Loading/Loading";

function ModContenuto() {
	const cookie = new Cookies();
	var data;
	data = { token: cookie.get("token") };
	const slug = useLocation().pathname.substring(14);
	console.log(slug);

	const [contenuto, setContenuto] = useState([]);
	const [loaded, setLoaded] = useState(false);

	const handleGet = async () => {
		const headers = {
			"Access-Control-Allow-Origin": "*",
		};

		console.log("token", data);
		const url = process.env.REACT_APP_API_URL + "/dash/getcontenuti";
		axios
			.post(url, data, { headers })
			.then((res) => {
				console.log("res", res);
				res.data.contenuti.map((content, i) => {
					if (content._id === slug) {
						setContenuto(content);
					}
				});
				setLoaded(true);
			})

			.catch((err) => {
				console.log("err", err);
			});
	};

	useEffect(() => {
		handleGet();
	}, []);

	return (
		<div>
			{!loaded ? (
				<div></div>
			) : (
				<div className="mt-36 px-44 ">
					<Title text={"Modifica Articolo"} />
					<ContenutoNuovo nuovo={false} init={contenuto} />
				</div>
			)}
		</div>
	);
}

export default ModContenuto;
