import React, { useState } from "react";

function TrashBtn({ id, handleDelete }) {
	const trash = "trash-" + id;
	const text = "text-" + id;
	const blue = "blue-" + id;
	const white = "white-" + id;

	var open = false;

	const handleOpen = () => {
		const trashid = document.getElementById(trash);
		const textid = document.getElementById(text);
		const whiteid = document.getElementById(white);
		const blueid = document.getElementById(blue);
		//trashid.style.transform = "translate(0px, -2.5px)";
		trashid.classList.add("transition-transform");
		textid.style.display = "block";
		whiteid.style.display = "block";
		blueid.style.display = "none";
		trashid.style.position = "absolute";
		trashid.style.backgroundColor = "#cf1111";
		trashid.style.width = "140px";
		trashid.style.paddingLeft = "15px";
		trashid.style.paddingRight = "10px";
		trashid.style.paddingTop = "3px";
		trashid.style.paddingBottom = "3px";
		setTimeout(()=>open = true,100)
	};
	const handleClose = () => {
		const trashid = document.getElementById(trash);
		const textid = document.getElementById(text);
		const whiteid = document.getElementById(white);
		const blueid = document.getElementById(blue);
		textid.style.display = "none";
		whiteid.style.display = "none";
		blueid.style.display = "block";
		trashid.style.width = "auto";
		trashid.style.transform = "translate(0px, 0px)";

		trashid.style.position = "static";
		trashid.style.backgroundColor = "rgba(255, 255, 255, 0)";

		trashid.style.paddingLeft = "0px";
		trashid.style.paddingRight = "0px";
		trashid.style.paddingTop = "0px";
		trashid.style.paddingBottom = "0px";
		trashid.classList.remove("transtion-transform2");
		open = false;
	};

	const handleClick = () => {
		handleDelete();
	};
	window.addEventListener("click", (e) => {
		const trashid = document.getElementById(trash);

		console.log("open", open);

		if (!open) {
			if (trashid.contains(e.target)) {
        handleOpen();
      }
		} else {
			if (!trashid.contains(e.target)) {
        handleClose();
			}else{
        handleClick();
      }
		}
	});


	return (
		<div
			id={trash}
			onClick={() => {
				!open && handleOpen();
			}}
			className="relative origin-top-right right-0 flex rounded-full items-center text-white cursor-pointer"
		>
			<p id={text} className="mr-2 font-bold hidden">
				Eliminare?
			</p>
			<p id={blue} className="text-orange text-[24px]">
				<i className="fa-regular fa-trash-can"></i>
			</p>
			<p id={white} className="text-white hidden text-[24px]">
				<i className="fa-regular fa-trash-can"></i>
			</p>
		</div>
	);
}

export default TrashBtn;
