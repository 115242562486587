import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import Cookies from "universal-cookie";
import IsLogged from "../../Components/Auth/IsLogged/IsLogged";
import lock from "../../assets/icons/lock-solid.svg";
import Loading from "../../Components/Molecules/Loading/Loading";
import AccessoEsclusivo from "../../Components/Atoms/AccessoEsclusivo/AccessoEsclusivo";
import NotificaAperta from "../../Components/Molecules/SchedaNotifica/NotificaAperta";
import Title from "../../Components/Atoms/Texts/Title";
import SchedaNotifica from "../../Components/Molecules/SchedaNotifica/SchedaNotifica";
import SchedaContenuto from "../../Components/Molecules/SchedaContenuto/SchedaContenuto";

function Admin() {
	IsLogged();
	const cookie = new Cookies();
	const [loaded, setLoaded] = useState(false);
	const [notifications, setNotifications] = useState([]);
	const [contenuti, setContenuti] = useState([]);
	var data;
	data = { token: cookie.get("token") };
	const handlenotification = async (data) => {
		const headers = {
			"Access-Control-Allow-Origin": "*",
		};

		console.log("token", data);
		const url = process.env.REACT_APP_API_URL + "/dash/notifiche";
		axios
			.post(url, data, { headers })
			.then((res) => {
				console.log("res", res);
				if (res.data.noti) {
					setNotifications(res.data.noti.reverse());
				}
				setLoaded(true);
			})
			.catch((err) => {
				console.log("err", err);
			});
	};

	const handlecontenuti = async () => {
		const headers = {
			"Access-Control-Allow-Origin": "*",
		};

		const url = process.env.REACT_APP_API_URL + "/dash/getcontenuti";
		axios
			.post(url, data, { headers })
			.then((res) => {
				var cont = res.data.contenuti;

				console.log("contenuti", cont);
				setContenuti(cont);
			})
			.catch((err) => {
				console.log("err", err);
			});
	};

	useEffect(() => {
		handlenotification(data);
		handlecontenuti();
		setTimeout(() => {
			setLoaded(true);
		}, 10000);
	}, []);

	return (
		<div className="bg-background w-full h-[100vh]">
			<div className="mt-32 mb-10 flex text-white text-lg justify-evenly">
				<a href="/admin">Admin</a>
				<a href="/notifiche">Notifiche</a>
				<a href="/contenuti">Contenuti</a>
			</div>
			{!loaded ? (
				<div></div>
			) : (
				<>
					<div className=" pb-24 px-4 md:px-44">
						<div>
							<Title text="Notifiche" />
							<div className="mt-7">
								{notifications.length &&
									notifications.map((notifica, i) => {
										if (i < 3) {
											return (
												<SchedaNotifica
													props={notifica}
													setNotifiche={setNotifications}
												/>
											);
										}
									})}
							</div>
						</div>
						<div className="mt-24">
							<Title text="Blog" />
							{contenuti.length && (
								contenuti.map((contenuto,i)=>{
									if (i < 3) {
										return (
											<SchedaContenuto data={contenuto} />
										);
									}
								})
								
							)}
						</div>
					</div>
				</>
			)}
		</div>
	);
}

export default Admin;
