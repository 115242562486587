import axios from "axios";
import React, { useEffect, useState } from "react";
import "../Home/Home.css";
import Cookies from "universal-cookie";
import AccessoEsclusivo from "../../Components/Atoms/AccessoEsclusivo/AccessoEsclusivo";

function Login() {
  const [data, setData] = useState({
    name: "",
    password: "",
    mail: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const cookies = new Cookies();
  const [response, setResponse] = useState('')

  const session = window.sessionStorage.getItem("logged");
  useEffect(() => {
    console.log(session);

    if (session > 0) {
      window.location.href = "/admin";
    }
    setLoaded(true);
  }, [session]);

  const handleChange = ({ currentTarget: input }) => {
    setData({ ...data, [input.name]: input.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const headers = {
          "Access-Control-Allow-Origin": "*",
      }
      setData({ ...data, password: data.password });
      const url = process.env.REACT_APP_API_URL + "/login/auth";
      axios.post(url, data, {headers}).then(res =>{
        setResponse(res.data.response);
        console.log(res);
        console.log(response);
        cookies.set("token", res.data.response.token);
        window.sessionStorage.setItem("logged", response.auth);
      })

      //window.location.href = "/admin";
    } catch (error) {
      console.log(error);
    }
  };

  const eyeclose = () => {
    return (
      <button
        type="button"
        onClick={() => setShowPassword(!showPassword)}
        className=" translate-x-[-150%] "
      >
        <i className={`fa-solid fa-eye-slash text-orange`}></i>
      </button>
    );
  };
  const eyeopen = () => {
    return (
      <button
        type="button"
        onClick={() => setShowPassword(!showPassword)}
        className=" translate-x-[-150%] "
      >
        <i className={`fa-solid fa-eye text-orange`}></i>
      </button>
    );
  };

  return (
    <div className="w-full p-7 h-[100vh] bg-background flex justify-center items-center fixed">
      <div className="mt-20">
        <div className="grid py-3 gap-16 md:grid-cols-2 md:py-20 md:gap-32 md:justify-items-center">
          <div className="flex items-center ">
            <div className="">
              <h1 className="text-orange text-4xl md:text-7xl font-bold textshadow">
                Area clienti
              </h1>
              <h2 className="text-orange text-2xl md:text-4xl font-bold textshadow">
                Webinal
              </h2>
            </div>
          </div>
          <div className="flex items-center">
            <form
              onSubmit={handleSubmit}
              className="grid grid-cols-1 gap-3 w-[100%]"
            >
              <input
                className=" w-[100%] md:w-96 p-2 md:pl-4 rounded-md shadow-lg"
                name="mail"
                onChange={handleChange}
                value={data.mail}
                placeholder="Email*"
                type="text"
              />
              <div className="relative w-full">
                <input
                  className=" w-[100%] md:w-96 p-2 md:pl-4 rounded-md shadow-lg"
                  name="password"
                  onChange={handleChange}
                  value={data.password}
                  placeholder="Password*"
                  type={showPassword ? "text" : "password"}
                />
                <button
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}
                  className=" absolute translate-y-[50%] translate-x-[-125%] text-sm "
                >
                  {showPassword === true ? <p>Hide</p> : <p>Show</p>}
                </button>
              </div>
              <a className="text-purple cursor-pointer ml-5" href="">
                Password dimenticata?
              </a>
              <button
                type="submit"
                className="h-12 w-24 text-white shadow-lg bg-orange font-bold rounded-full transition-all duration-200 hover:bg-white hover:text-orange hover:border-[2px] hover:border-orange hover:scale-110"
              >
                Accedi
              </button>
            </form>
          </div>
        </div>
        <AccessoEsclusivo/>
      </div>
    </div>
  );
}

export default Login;
