import React, { useRef, useState } from "react";
import FileUploadbtn from "../../Atoms/Buttons/FileUploadbtn/FileUploadbtn";

import { Textarea, Input } from "@material-tailwind/react";
import TrashBtn from "../../Atoms/Buttons/TrashBtn/TrashBtn";
import axios from "axios";
import Cookies from "universal-cookie";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useEffect } from "react";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import storage from "../../../firebase";
import ImageUploader from "quill-image-uploader";
import Editor from "./Editor";
import IsLogged from "../../Auth/IsLogged/IsLogged";

Quill.register("modules/imageUploader", ImageUploader);

function ContenutoNuovo({ nuovo, init }) {
	IsLogged();
	const cookie = new Cookies();
	const [testo, setTesto] = useState(nuovo ? "Scrivi qui..." : init.testo);
	const [data, setData] = useState({
		token: cookie.get("token"),
		img: nuovo ? "" : init.img,
		titolo: nuovo ? "" : init.titolo,
		descr: nuovo ? "" : init.descr,
		testo: nuovo ? "" : init.testo,
		id: nuovo ? null : init._id,
	});
	//console.log(init);
	
	useEffect(() => {
		console.log("data", data);
		handleChange();
	}, [testo]);

	const handleChange = async () => {
		await setData({ ...data, testo: testo });
	};

	const handleInputState = (name, value) => {
		setData((prev) => ({ ...prev, [name]: value }));
		setTimeout(()=>console.log(data),500)
	};

	const handleSubmit = async (e) => {
		await handleChange();
		setData({ ...data, testo: testo });
		e.preventDefault();
		console.log("data", data);
		setTimeout(() => {
			try {
				console.log(data);
				const headers = {
					"Access-Control-Allow-Origin": "*",
				};
				const url = nuovo
					? process.env.REACT_APP_API_URL + "/dash/createarticle"
					: process.env.REACT_APP_API_URL + "/dash/updatearticle";
				axios.post(url, data, { headers }).then((res) => {
					console.log("res",res.data);
					if (res.data.Content) {
						window.location.href = '/contenuti'
					}
					else{
						window.alert('Errore nel salvataggio');
					}
				});
			} catch (e) {
				console.log(e);
			}
		}, 200);
	};

	const headers = {
		"Access-Control-Allow-Origin": "*",
	};
	const handleDelete = () => {
		if (nuovo) {
			window.location.href = "contenuti";
		} else {
			const request = { id: data.id };
			axios
				.post(process.env.REACT_APP_API_URL + "/dash/deletearticle", request, {
					headers,
				})
				.then((response) => {
					console.log(response);
					window.location.href = "/contenuti/";
				});
		}
	};

	return (
		<form onSubmit={handleSubmit}>
			<div className="bg-cultured my-9 rounded-xl grid grid-cols-1 gap-8 p-9">
				<div className="flex justify-between">
					<div className="w-96">
						<label className="relative block overflow-hidden rounded-md  border-purple border px-3 pt-3 shadow-sm focus-within:border-orange focus-within:ring-1 focus-within:ring-orange">
							<input
								value={data.titolo}
								onChange={(e) =>
									setData({ ...data, titolo: e.currentTarget.value })
								}
								type="text"
								placeholder={"Titolo"}
								className="peer h-8 w-full border-none bg-transparent p-0 placeholder-transparent focus:border-transparent focus:outline-none focus:ring-0 sm:text-sm"
							/>

							<span className="absolute left-3 top-2 -translate-y-1/2 text-xs  transition-all peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-sm peer-focus:top-2 peer-focus:text-xs">
								{"Titolo"}
							</span>
						</label>
					</div>
					<div className="relative">
						<TrashBtn id={0} handleDelete={handleDelete} />
					</div>
				</div>
				<div className="w-[50%]">
					<label className="relative block overflow-hidden rounded-md  border-purple border px-3 pt-3 shadow-sm focus-within:border-orange focus-within:ring-1 focus-within:ring-orange">
						<textarea
							value={data.descr}
							onChange={(e) =>
								setData({ ...data, descr: e.currentTarget.value })
							}
							placeholder="Description"
							className="peer h-16 w-full border-none bg-transparent p-0 placeholder-transparent placeholder:top-0 focus:border-transparent focus:outline-none focus:ring-0 sm:text-sm"
						/>

						<span className="absolute left-3 top-2 -translate-y-1/2 text-xs  transition-all  peer-placeholder-shown:text-sm peer-focus:top-2 peer-focus:text-xs">
							Descrizione
						</span>
					</label>
				</div>
				<div className="flex place-self-center w-48">
					<FileUploadbtn
						name="img"
						handleInputState={handleInputState}
						type="image"
						value={data.img}
					/>
				</div>
				<div className="w-full">
					<span className="-translate-y-1/2 text-sm transition-all  peer-placeholder-shown:text-sm peer-focus:top-2 peer-focus:text-xs">
						Testo
					</span>
					<Editor setTesto={setTesto} testo={testo}/>
				</div>
			</div>
			<div className="fixed bottom-10 right-10 justify-end">
				<button
					className="flex gap-3 items-center px-5 py-3 text-white shadow-lg bg-orange font-bold rounded-full transition-all duration-200 fill-white hover:fill-orange hover:bg-white hover:text-orange hover:border-[2px] hover:border-orange hover:scale-110"
					type={"submit"}
				>
					<p className="w-auto">{"Pubblica"}</p>
					<div className="w-8">
						<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
							<path d="M144 480C64.5 480 0 415.5 0 336c0-62.8 40.2-116.2 96.2-135.9c-.1-2.7-.2-5.4-.2-8.1c0-88.4 71.6-160 160-160c59.3 0 111 32.2 138.7 80.2C409.9 102 428.3 96 448 96c53 0 96 43 96 96c0 12.2-2.3 23.8-6.4 34.6C596 238.4 640 290.1 640 352c0 70.7-57.3 128-128 128H144zm79-217c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l39-39V392c0 13.3 10.7 24 24 24s24-10.7 24-24V257.9l39 39c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-80-80c-9.4-9.4-24.6-9.4-33.9 0l-80 80z" />
						</svg>
					</div>
				</button>
			</div>
		</form>
	);
}

export default ContenutoNuovo;

/*
<textarea
							value={data.testo}
							onChange={(e) =>
								setData({ ...data, testo: e.currentTarget.value })
							}
							placeholder="Messaggio"
							className="peer h-16 w-full border-none bg-transparent p-0 placeholder-transparent placeholder:top-0 focus:border-transparent focus:outline-none focus:ring-0 sm:text-sm"
						/>
*/
