import React, { useState } from "react";
import Home from "./Pages/Home";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import DinamIcIsland from "./Components/Molecules/DinamIcIsland";
import Login from "./Pages/Login/Login";
import Admin from "./Pages/Admin/Admin";
import Notifiche from "./Pages/Notifiche/Notifiche";
import Contenuti from "./Pages/Contenuti/Contenuti";
import Notifica from "./Pages/Notifica/Notifica";
import Contenuto from "./Pages/Contenuto/Contenuto";
import ModContenuto from "./Pages/Contenuto/ModContenuto";
import NEWLogin from "./Pages/Login/NEWLogin";
import Footers from "./Components/Molecules/Footers/Footers";
import Contatti from "./Pages/Contatti/Contatti";


function App() {


  return (
    <div>
      <BrowserRouter>
      <DinamIcIsland  load={['/admin','/contenuti', '/notifiche', '/modcontenuto']}/>
        <Routes>
          <Route path={`/`} element={<Home/>} />
          <Route path={`/contatti`} element={<Contatti/>} />
          <Route path={`/login`} element={<NEWLogin/>} />
          <Route path={`/admin`} element={<Admin />} />
          <Route path={`/notifiche`} element={<Notifiche />} />
          <Route path={`/contenuti`} element={<Contenuti />} />
          <Route path={`/contenuto`} element={<Contenuto/>} />
          <Route path={`/modcontenuto/:id`} element={<ModContenuto/>} />
          <Route path={`/notifica/:id`} element={<Notifica />} />


          
        </Routes>
      
      </BrowserRouter>
    </div>
  );
}

export default App;
