import React, { useState } from "react";
import anime from "animejs";
import { useEffect } from "react";
import axios from "axios";
import Cookies from "universal-cookie";

function NEWLogin() {
	useEffect(() => {
		var current = null;
		document.querySelector("#email").addEventListener("focus", function (e) {
			if (current) current.pause();
			current = anime({
				targets: "path",
				strokeDashoffset: {
					value: 0,
					duration: 700,
					easing: "easeOutQuart",
				},
				strokeDasharray: {
					value: "240 1386",
					duration: 700,
					easing: "easeOutQuart",
				},
			});
		});
		document.querySelector("#password").addEventListener("focus", function (e) {
			if (current) current.pause();
			current = anime({
				targets: "path",
				strokeDashoffset: {
					value: -336,
					duration: 700,
					easing: "easeOutQuart",
				},
				strokeDasharray: {
					value: "240 1386",
					duration: 700,
					easing: "easeOutQuart",
				},
			});
		});
		document.querySelector("#submit").addEventListener("focus", function (e) {
			if (current) current.pause();
			current = anime({
				targets: "path",
				strokeDashoffset: {
					value: -730,
					duration: 700,
					easing: "easeOutQuart",
				},
				strokeDasharray: {
					value: "530 1386",
					duration: 700,
					easing: "easeOutQuart",
				},
			});
		});
	});
	const [data, setData] = useState({
		name: "",
		password: "",
		mail: "",
	});
	const [showPassword, setShowPassword] = useState(false);
	const [loaded, setLoaded] = useState(false);
	const cookies = new Cookies();
	const [response, setResponse] = useState("");

	const session = window.sessionStorage.getItem("logged");
	useEffect(() => {
		console.log(session);

		if (session > 0) {
			window.location.href = "/admin";
		}
		setLoaded(true);
	}, [session]);

	const handleChange = ({ currentTarget: input }) => {
		setData({ ...data, [input.name]: input.value });
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			const headers = {
				"Access-Control-Allow-Origin": "*",
			};
			setData({ ...data, password: data.password });
			const url = process.env.REACT_APP_API_URL + "/login/auth";
			await axios.post(url, data, { headers }).then((res) => {
				setResponse(res.data.response);
				console.log(res);
				console.log(response);
				cookies.set("token", res.data.response.token);
				window.sessionStorage.setItem("logged", response.auth);
				window.location.href = "/admin";
			});

		} catch (error) {
			console.log(error);
		}
	};
	return (
		<div className="page">
			<form onSubmit={handleSubmit} className="container">
				<div className="left ">
					<div className=" w-full">
						<h1 className="text-orange text-4xl md:text-7xl font-bold textshadow">
							Area clienti
						</h1>
						<h2 className="text-orange text-2xl md:text-4xl font-bold textshadow">
							Webinal
						</h2>
					</div>
				</div>
				<div className="right">
					<svg className="porco" viewBox="0 0 320 300">
						<defs>
							<linearGradient
								id="linearGradient"
								x1="13"
								y1="193.49992"
								x2="307"
								y2="193.49992"
								gradientUnits="userSpaceOnUse"
							>
								<stop stopColor="var(--color-1)" offset="0" id="stop876" />
								<stop stopColor="var(--color-2)" offset="1" id="stop878" />
							</linearGradient>
						</defs>
						<path
							className="porco"
							d="m 40,120.00016 239.99984,-3.2e-4 c 0,0 24.99263,0.79932 25.00016,35.00016 0.008,34.20084 -25.00016,35 -25.00016,35 h -239.99984 c 0,-0.0205 -25,4.01348 -25,38.5 0,34.48652 25,38.5 25,38.5 h 215 c 0,0 20,-0.99604 20,-25 0,-24.00396 -20,-25 -20,-25 h -190 c 0,0 -20,1.71033 -20,25 0,24.00396 20,25 20,25 h 168.57143"
						/>
					</svg>
					<div className="form">
						<label className="lgn" htmlFor="email">
							Email
						</label>
						<input
							className="lgn"
							onChange={handleChange}
							value={data.mail}
							type="email"
							id="email"
                            name="mail"
						/>
						<label className="lgn" htmlFor="password">
							Password
						</label>
						<input
							className="lgn"
							type="password"
                            name="password"
							id="password"
							onChange={handleChange}
							value={data.password}
						/>
						<input className="" type="submit" id="submit" value="Accedi" />
					</div>
				</div>
			</form>
		</div>
	);
}

export default NEWLogin;
