import axios from "axios";
import React from "react";
import standard from "../../../assets/StandardImage.jpeg";
import TrashBtn from "../../Atoms/Buttons/TrashBtn/TrashBtn";

function SchedaContenuto({data}) {
  const headers = {
    "Access-Control-Allow-Origin": "*",
  };
  const handleDelete = () =>{
    const request = { id: data._id };
		axios
			.post(process.env.REACT_APP_API_URL + "/dash/deletearticle", request, {
				headers,
			})
			.then((response) => {
				console.log(response);
				window.location.href = "/contenuti/";
			});
  }

  return (
    <div className="grid grid-cols-12 gap-6 justify-items-center items-center py-5 mb-4 rounded-xl shadow-lg bg-cultured">
      <img
        onClick={() => {
          window.location.href = "/modcontenuto/"+data._id;
        }}
        className="rounded-xl col-span-2 cursor-pointer aspect-video"
        width={"60%"}
        height={""}
        src={data.img}
        alt=""
      />
      <div className="col-span-8 col-start-3 justify-self-start">
        <h3 className=" font-bold">{data.titolo}</h3>
        <p
          onClick={() => {
            window.location.href = "/modcontenuto/"+data._id;
          }}
          className=" text-justify cursor-pointer"
        >
          {data.descr}
        </p>
      </div>
      <div className="col-span-2 col-start-11 relative grid gap-x-7 grid-cols-3">
        <p
          onClick={() => {
            window.location.href = "/modcontenuto/"+data._id;
          }}
          className=" text-[24px] hover:cursor-pointer"
        >
          <i className="fa-regular fa-arrow-up-right-from-square"></i>
        </p>
        <p
          onClick={() => {
            window.location.href = "/modcontenuto/"+data._id;
          }}
          className=" text-[24px] hover:cursor-pointer"
        >
          <i className="fa-regular fa-pen-to-square"></i>
        </p>
        <TrashBtn id={data._id} handleDelete={handleDelete} />
      </div>
    </div>
  );
}

export default SchedaContenuto;
