import React from "react";

function NEWARTbtn({ title, link, svg, type }) {
  return (
    <button
      className="flex gap-3 items-center px-5 py-3 text-white shadow-lg bg-orange font-bold rounded-full transition-all duration-200 fill-white hover:fill-orange hover:bg-white hover:text-orange hover:border-[2px] hover:border-orange hover:scale-110"
      type={type}
      onClick={()=>window.location.href = link}
    >
      <p className="w-auto">{title}</p>
      <div className="w-8">{svg}</div>
    </button>
  );
}

export default NEWARTbtn;
