import axios from "axios";
import React, { useEffect, useState } from "react";
import Cookies from "universal-cookie";

function IsLogged() {
	const cookie = new Cookies();
	const [loaded, setLoaded] = useState(true);
	useEffect(() => {
		const data = { token: cookie.get("token") };

		handlesign(data);
		setLoaded(true);
	}, []);

	const handlesign = async (data) => {
		try {
			if (!data.token) {
				window.location.href = "/login";
			}

			const url = process.env.REACT_APP_API_URL + "/login/checktoken";
			const { data: res } = await axios.post(url, data);

			const response = res.message;
			if (response !== "logged") {
				console.log("not logged");
				window.location.href = "/login";
			}
		} catch (error) {
			console.log(error);
		}
	};
	return <div>WithAuth</div>;
}

export default IsLogged;
