import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import Cookies from "universal-cookie";
import IsLogged from "../../Components/Auth/IsLogged/IsLogged";
import lock from "../../assets/icons/lock-solid.svg";
import Loading from "../../Components/Molecules/Loading/Loading";
import Title from "../../Components/Atoms/Texts/Title";
import SchedaNotifica from "../../Components/Molecules/SchedaNotifica/SchedaNotifica";
import AccessoEsclusivo from "../../Components/Atoms/AccessoEsclusivo/AccessoEsclusivo";

function Notifiche() {
	IsLogged();
	const cookie = new Cookies();
	const [notifications, setNotifications] = useState([]);
	const [loaded, setLoaded] = useState(false);
	const [filtro, setFiltro] = useState(1);
	var data;
	data = { token: cookie.get("token") };

	const handlenotification = async (data) => {
		const headers = {
			"Access-Control-Allow-Origin": "*",
		};

		console.log("token", data);
		const url = process.env.REACT_APP_API_URL + "/dash/notifiche";
		axios
			.post(url, data, { headers })
			.then((res) => {
				console.log("res", res);
				setNotifications(res.data.noti.reverse());
			})
			.catch((err) => {
				console.log("err", err);
			});
	};

	useEffect(() => {
		handlenotification(data);
		setTimeout(() => {
			setLoaded(true);
		}, 500);
	}, []);

	return (
		<div className="bg-background w-full h-[100vh]">
			<div className="mt-32 mb-10 flex text-white text-lg justify-evenly">
				<a href="/admin">Admin</a>
				<a href="/notifiche">Notifiche</a>
				<a href="/contenuti">Contenuti</a>
			</div>
			{!loaded ? (
				<div></div>
			) : (
				<>
					<div className="mt-16 md:px-44 relative">
						<div className="sticky top-12">
							<Title text={"Notifiche"} />
						</div>
						<div className="grid grid-cols-12  gap-10 mt-10">
							<div className="h-44 max-w-[95vw] p-3 sticky top-36 md:col-span-3 col-span-12 shadow-xl grid grid-cols-1 gap-4 bg-cultured rounded-xl">
								<div
									className="flex items-center gap-5 fill-orange  cursor-pointer"
									onClick={() => {
										setFiltro(1);
									}}
								>
									<div className="w-8">
									<div className="w-8">
										{filtro === 1 ? (
											<svg
												xmlns="http://www.w3.org/2000/svg"
												viewBox="0 0 512 512"
											>
												<path d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48H48zM0 176V384c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V176L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z" />
											</svg>
										) : (
											<svg
												xmlns="http://www.w3.org/2000/svg"
												viewBox="0 0 512 512"
											>
												<path d="M64 112c-8.8 0-16 7.2-16 16v22.1L220.5 291.7c20.7 17 50.4 17 71.1 0L464 150.1V128c0-8.8-7.2-16-16-16H64zM48 212.2V384c0 8.8 7.2 16 16 16H448c8.8 0 16-7.2 16-16V212.2L322 328.8c-38.4 31.5-93.7 31.5-132 0L48 212.2zM0 128C0 92.7 28.7 64 64 64H448c35.3 0 64 28.7 64 64V384c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V128z" />
											</svg>
										)}
									</div>
									</div>
									<p
										href="/notifiche"
										className="text-base font-semibold place-self-center"
									>
										In entrata
									</p>
								</div>
								<div
									className="flex items-center gap-5  cursor-pointer"
									onClick={() => {
										setFiltro(2);
									}}
								>
									{" "}
									<div className="w-8">
									<div className="w-8">
										{filtro === 2 ? (
											<svg
												className="fill-star"
												xmlns="http://www.w3.org/2000/svg"
												viewBox="0 0 576 512"
											>
												<path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z" />
											</svg>
										) : (
											<svg
												className="fill-orange"
												xmlns="http://www.w3.org/2000/svg"
												viewBox="0 0 576 512"
											>
												<path d="M287.9 0C297.1 0 305.5 5.25 309.5 13.52L378.1 154.8L531.4 177.5C540.4 178.8 547.8 185.1 550.7 193.7C553.5 202.4 551.2 211.9 544.8 218.2L433.6 328.4L459.9 483.9C461.4 492.9 457.7 502.1 450.2 507.4C442.8 512.7 432.1 513.4 424.9 509.1L287.9 435.9L150.1 509.1C142.9 513.4 133.1 512.7 125.6 507.4C118.2 502.1 114.5 492.9 115.1 483.9L142.2 328.4L31.11 218.2C24.65 211.9 22.36 202.4 25.2 193.7C28.03 185.1 35.5 178.8 44.49 177.5L197.7 154.8L266.3 13.52C270.4 5.249 278.7 0 287.9 0L287.9 0zM287.9 78.95L235.4 187.2C231.9 194.3 225.1 199.3 217.3 200.5L98.98 217.9L184.9 303C190.4 308.5 192.9 316.4 191.6 324.1L171.4 443.7L276.6 387.5C283.7 383.7 292.2 383.7 299.2 387.5L404.4 443.7L384.2 324.1C382.9 316.4 385.5 308.5 391 303L476.9 217.9L358.6 200.5C350.7 199.3 343.9 194.3 340.5 187.2L287.9 78.95z" />
											</svg>
										)}
									</div>
									</div>
									<p
										href=""
										className="text-base font-semibold place-self-center "
									>
										Importanti
									</p>
								</div>
								<div
									className="flex items-center gap-5  cursor-pointer"
									onClick={() => {
										setFiltro(3);
									}}
								>
									{" "}
									<div className="w-8 flex justify-center">
									<div className="w-6 ml-[px]">
										{filtro === 3 ? (
											<svg
												className="fill-red"
												xmlns="http://www.w3.org/2000/svg"
												viewBox="0 0 448 512"
											>
												<path d="M135.2 17.7C140.6 6.8 151.7 0 163.8 0H284.2c12.1 0 23.2 6.8 28.6 17.7L320 32h96c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 96 0 81.7 0 64S14.3 32 32 32h96l7.2-14.3zM32 128H416V448c0 35.3-28.7 64-64 64H96c-35.3 0-64-28.7-64-64V128zm96 64c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16z" />
											</svg>
										) : (
											<svg
												className="fill-orange"
												xmlns="http://www.w3.org/2000/svg"
												viewBox="0 0 448 512"
											>
												<path d="M160 400C160 408.8 152.8 416 144 416C135.2 416 128 408.8 128 400V192C128 183.2 135.2 176 144 176C152.8 176 160 183.2 160 192V400zM240 400C240 408.8 232.8 416 224 416C215.2 416 208 408.8 208 400V192C208 183.2 215.2 176 224 176C232.8 176 240 183.2 240 192V400zM320 400C320 408.8 312.8 416 304 416C295.2 416 288 408.8 288 400V192C288 183.2 295.2 176 304 176C312.8 176 320 183.2 320 192V400zM317.5 24.94L354.2 80H424C437.3 80 448 90.75 448 104C448 117.3 437.3 128 424 128H416V432C416 476.2 380.2 512 336 512H112C67.82 512 32 476.2 32 432V128H24C10.75 128 0 117.3 0 104C0 90.75 10.75 80 24 80H93.82L130.5 24.94C140.9 9.357 158.4 0 177.1 0H270.9C289.6 0 307.1 9.358 317.5 24.94H317.5zM151.5 80H296.5L277.5 51.56C276 49.34 273.5 48 270.9 48H177.1C174.5 48 171.1 49.34 170.5 51.56L151.5 80zM80 432C80 449.7 94.33 464 112 464H336C353.7 464 368 449.7 368 432V128H80V432z" />
											</svg>
										)}
									</div>
									</div>
									<p
										href=""
										className="text-base font-semibold place-self-center"
									>
										Cestino
									</p>
								</div>
							</div>
							<div className=" col-span-12 max-w-[95vw] md:col-span-9 md:col-start-4">
								{notifications.map((item, i) => {
									if (filtro === 1) {
										if (!item.eliminato) {
											return (
												<SchedaNotifica
													key={i}
													props={item}
													setNotifiche={setNotifications}
													notifications={notifications}
												/>
											);
										}
									} else if (filtro === 2) {
										if (item.importante && !item.eliminato) {
											return (
												<SchedaNotifica
													key={i}
													props={item}
													setNotifiche={setNotifications}
													notifications={notifications}
												/>
											);
										}
									} else if (filtro === 3) {
										if (item.eliminato) {
											return (
												<SchedaNotifica
													key={i}
													props={item}
													setNotifiche={setNotifications}
													notifications={notifications}
												/>
											);
										}
									}
								})}
							</div>
						</div>
					</div>
				</>
			)}
		</div>
	);
}

export default Notifiche;
