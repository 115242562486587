import axios from "axios";
import React, { useEffect, useState } from "react";
import PopUpMenu from "../../Atoms/Buttons/PopUpMenu/PopUpMenu";

function SchedaNotifica({ props, setNotifiche, notifications }) {
	const [filtro, setFiltro] = useState({
		importante: props.importante,
		cestino: props.eliminato,
	});
	const [letto, setLetto] = useState(props.letto);
	const [clicked, setClicked] = useState(false);
	const data = {
		anno:props.createdAt.slice(0,4),
		mese:props.createdAt.slice(5,7),
		giorno:Number(props.createdAt.slice(8,10)),
		ora:Number(props.createdAt.slice(11,13))+1,
		minuto:props.createdAt.slice(14,16),
		secondo:props.createdAt.slice(17,19)
	}
	var today = Number(String(new Date().getTime()).slice(0,2));
	

	const handleClick = () => {
		const headers = {
			"Access-Control-Allow-Origin": "*",
		};
		const request = { id: props._id, letto: true };
		axios
			.post(process.env.REACT_APP_API_URL + "/dash/notificaletta", request, {
				headers,
			})
			.then((response) => {
				console.log(response);
				window.location.href = "/notifica/" + props._id;
			});
	};

	useEffect(() => {
		
		if (clicked) {
			setNotifiche((current) =>
				current.map((obj) => {
					if (obj._id === props._id) {
						return {
							...obj,
							letto: letto,
							importante: filtro.importante,
							eliminato: filtro.cestino,
						};
					}
					return obj;
				})
			);
			setClicked(false);
			
		}
	});

	return (
		<div
			key={props._id}
			className={` ${
				props.letto ? "font-normal" : "font-semibold"
			} w-full max-w-[95vw] grid grid-cols-12 bg-cultured p-5 mb-4 rounded-xl shadow-xl`}
		>
			<p onClick={() => handleClick()} className="cursor-pointer col-span-2">
				{props.name} {props.lastname}
			</p>
			<p onClick={() => handleClick()} className="cursor-pointer col-span-7">
				{props.msg.slice(0, 50)}...
			</p>
			<p
				onClick={() => handleClick()}
				className="cursor-pointer col-span-1 col-start-11 flex justify-center"
			>
				{today !== data.giorno ? data.giorno + '/' + data.mese + '/' + data.anno : data.ora + ':' + data.minuto}
			</p>
			<PopUpMenu
				id={props._id}
				setLetto={setLetto}
				letto={letto}
				setClicked={setClicked}
				setFiltro={setFiltro}
				setNotifiche={setNotifiche}
				notifiche={notifications}
				filtro={filtro}
				className="col-start-12 flex justify-center md:translate-x-0"
			/>
		</div>
	);
}

export default SchedaNotifica;
